.preview-fifthTrait {
    border-radius: 10px;
}
.preview-fifthTrait-title {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 76%;
    /* or 18px */

    text-transform: uppercase;
    color: #FFFFFF;
}

.preview-fifthTrait-trait{
    border-radius: 10px;
    width: 129;
    height: 85px;
    background: #292929;
}

.preview-fifthTrait img {
    border-radius: 10px;
    width: 129;
    height: 85px;
 
}

.preview-fifthTrait:hover{
    cursor: pointer;
}

.preview-fifthTrait-selected{
    outline-offset: -2px;
    outline: 2px solid palevioletred;
}

.preview-fifthTrait-Notselected{
    outline-offset: -2px;
    outline: 2px solid gray;
}