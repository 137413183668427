.footer-section{
    color:#fff;
    padding: 0 0 200px 0;
    margin-bottom:0px !important;
    margin-top: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-img-container{

    padding: 0px 0px;
    text-align: center;
}

.footer-img-container img{
    width:25%;
}

.footer-logos-container{
    display: flex;
    justify-content: center;
}

.footer-logos-container img{
    width:25px;
    margin: auto 10px;
}

.footer-credits-container{
    display: flex;
    justify-content: center;
    width:100%;
    font-size: 12px;
    padding: 40px 0px 0px 0px;
}

.footer-credits-a{
    width:50%;
    text-align: right;
    padding-right: 10px;
}

.footer-credits-a a{
    text-decoration: none;
    color:#fff;
}

.footer-powered{
    width:50%;
    display: flex;
    text-align: left;
}

.footer-powered img{
    width:80px;
}

@media screen and (max-width: 992px) {

    .footer-img-container img{
        width:40%;
    }
}

@media screen and (max-width: 425px) {

    .footer-img-container img{
        margin-top: 50px;
        width:70%;
    }

    .footer-credits-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:100%;
        font-size: 12px;
        padding: 40px 0px 0px 0px;
    }

    .footer-credits-a{
        width:100%;
        text-align: center;
        
    }
    
    .footer-powered{
        margin-top: 10px;
        width:100%;
        text-align: center !important;
        display: flex;
        justify-content: center;
    }

    .footer-powered img{
        width:100px;
        margin-left: 5px;
    }

    .footer-powered div{
        padding-top: 4px;
    }
}