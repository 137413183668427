.preview {
    /* margin-top: -32px; */
    /* margin-left: 25px; */
    /* margin-bottom: -35px;  */
    
}

.preview svg {
    justify-content: center;
    align-items: center;
    height: 41vh;
    width: 100%;
}

.preview image {
    height: 41vh;
    width: 100%;
}
.preview-hover {
    cursor: pointer;
}
/* .preview-title {
    padding: 1px;
    color: #fff;
    align-items: center;
    text-decoration: none;
    line-height: 20px;
    height: 100%;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
} */

