.general-container{
    width:100%;
    display: flex;
    justify-content: center;
    
}

.general-container-int{
    width:100%;
}
