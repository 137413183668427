.boonji-main{
    width:100%;
    position:relative;
    height: 200px;
    overflow: hidden;
    text-align: center;
}

.boonji-main-img{
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.boonji-main:hover{
    cursor: pointer;
}

.boonji-main-img-selected{
    height: 100%;
    border-radius: 5px;
    object-fit: cover;

    outline: 5px solid #DF7DA3;
    outline-offset: -5px;
}

.boonji-main-img-selected-notOwned{
    height: 100%;
    border-radius: 5px;
    object-fit: cover;

    outline: 5px solid greenyellow;
    outline-offset: -5px;
}
.boonji-main p{
    position:absolute;
    width:96%;
    margin-left: 5px;
    bottom:0px;
    margin-bottom: 10px;
    text-align: center;
    background-color: #16011D;
    text-shadow: 0px 0px 7px #674BA2;
}

.boonji-main-Opensea{
    position:absolute;
    width:96%;
    margin-left: 5px;
    align-items: center;
    justify-items: center;
    top:10px;
    margin-top: 10px;
    text-align: center;
    background-color: #16011D;
    text-shadow: 0px 0px 7px #674BA2;
}
.boonji-main-Opensea a{
    text-decoration: none;
    color:#DF7DA3;  
}



