.inProgress {
    background: black;
    background: url('../../../assets/new-twinkle.gif');
    border: 2px solid #674BA2;
}


.inProgress-title{
    font-family: 'DM Sans', sans-serif;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 80%;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 15px;
}
.inProgress-message{
    font-family: 'DM Sans', sans-serif;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 80%;
    text-align: center;
    padding-bottom: 15px;
}
