.phases-section{
    padding: 0 40px;
    color:#fff;
    display: block;
    max-width: 1170px;
    margin: auto;
}

.roadmap-phase-number{
    margin-bottom: 10px;
    color: #784fb1;
    line-height: 100%;
    font-weight: 700;
    text-align: left;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.road-map-h3{
    color: #df7da3;
    font-size: 24px;
    text-align: left;
    letter-spacing: 6px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.road-map-h2{
    font-size: 55px;
    text-align: left;
    margin-top: 0px;
    font-weight: bold;
    letter-spacing: 20px;
}

@media screen and (max-width: 992px) {
    .road-map-h2{
        font-size: 40px;
        letter-spacing: 14px;
    }
}

@media screen and (max-width: 768px) {

    .phases-section{
        padding: 0px 30px;
    }

    .roadmap-phase-number{
        text-align: center;
    }

    .road-map-h3{
        text-align: center;
        font-size: 13px;
    }

    .road-map-h2{
        font-size: 40px;
        text-align: center;
        letter-spacing: 12px;
    }
}

@media screen and (max-width: 425px) {

    .phases-section{
        padding: 0px 15px;
    }
}