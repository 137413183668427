.specs-section{
   
}

.specs-section-container{
    display: flex;
    color:#fff;
    padding: 50px;
    max-width: 1170px;
}

.specs-section-container>div{
    width: 50%;
    padding: 40px 0 0 0;
}

.specs-section-container>div:first-child{
    padding: 40px 20px 0 0;
}

.specs-section-info-container{
    text-align: left;
}

.specs-section-info-container>div:first-child{
    text-align: center;
    padding: 30px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-image: linear-gradient(233deg, rgba(223, 125, 163, 0), rgba(223, 125, 163, 0.53) 45%, #a66ecd);
    background-image: linear-gradient(210deg, rgba(192, 117, 187, 0), rgba(192, 117, 187, 0.46) 46%, #a66ecd);
}

.specs-section-info-container p{
    text-align: left;
    padding: 0px 10px;
}

.specs-section-info-container a{
    color:#fff;
}

.specs-section-btns-container{
    display: flex;
}

.specs-btn-pink{ 
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 0px;
    background-color: #df7da3;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
}

.specs-btn-pink:hover{
    background-color: #784fb1; 
}

.specs-btn-purple{
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 20px;
    background-color: #291743;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
}

.specs-btn-purple:hover{
    background-color: #784fb1; 
}

@media screen and (max-width: 768px) {

    .specs-section-container{
 
        padding: 30px !important;
    }

}

@media screen and (max-width: 425px) {

    .specs-section-container{
        display: flex;
        flex-direction: column-reverse;
        padding: 0px !important;
    }

    .specs-section-info-container{
        text-align: center;
        width:100% !important;
        padding: 0px 15px !important;
    }

    .specs-section-info-container p{
        text-align: center;
    }

    .specs-section-desc{
        width: 100% !important;
        padding: 30px 15px !important;
    }

    .specs-section-desc h2{
        text-align: center;
    }

    .specs-section-desc h3{
        text-align: center;
    }
}



