.boonjiCardTrait_imageBackground{
    background-color: white;
    border-radius: 3px;
}

.boonjiCardTrait_type{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    
    color: #FFFFFF;
}

.boonjiCardTrait_name{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: normal;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
}

.boonjiCard-traitSelected{
    -webkit-box-shadow:inset 0px 0px 0px 3px  #A66ECD;
    -moz-box-shadow:inset 0px 0px 0px 3px  #A66ECD;
    box-shadow:inset 0px 0px 0px 3px  #A66ECD;

    outline: 3px solid #A66ECD;
    outline-offset: -3px;
}

.boonjiCard-traitNotOwned{
    -webkit-box-shadow:inset 0px 0px 0px 3px  greenyellow;
    -moz-box-shadow:inset 0px 0px 0px 3px  greenyellow;
    box-shadow:inset 0px 0px 0px 3px  greenyellow;

    outline: 3px solid greenyellow;
    outline-offset: -3px;
    
}


.boonjiCard_TraitReady:hover{
    cursor: pointer;
}

.boonjiCard_TraitLoading:hover{
    cursor: auto
}