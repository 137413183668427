.communji-section{
   
}

.communji-section-container{

    display: flex;
    padding: 0 50px;
    max-width: 1170px;

}

.communji-info{
    width:38%;
    padding-right: 20px;
}

.communji-info>div{
    padding: 20px 30px 20px 30px;
    border-radius: 12px;
    background-color: #593b83;
    min-height: 400px;
    width:96%;
}

.communji-info p{
    font-size: 14px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.communji-img{
    width:62%;
    
}

.communji-img img{
    width:100%;
    border-radius: 12px;

}

@media screen and (max-width: 992px) {

    .communji-section-container{
        display: block;
        padding: 0 30px;
    }

    .communji-info{
        width:100%;
        padding: 0px !important;
    }

    .communji-info>div{
        width: 100%;
        margin-bottom: 20px;
    }

    .communji-img{
        width:100%;
        height: auto !important;
    }
}

@media screen and (max-width: 425px) {

    .communji-section-container{
        display: block;
        padding: 0 15px;
    }

    .communji-info>div{
        padding: 20px 20px;
    }

    .communji-info>div h2{
        text-align: center;
        font-size: 20px;
    }
}