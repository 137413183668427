.fifthTraitImage{
    padding: 10px;
    background: #292929;
    border-radius: 23px;
    margin-left: 10px;
}
.fifthTraitImage img {
    margin: auto;
    width: 100%;
    height: auto;
}

.fifthTraitImage:hover{
    cursor: pointer;
}

.fifthTraitImage-selected{
    padding: 2px;
    background: #292929;
    
    border-radius: 23px;
    -webkit-box-shadow:inset 0px 0px 0px 3px  #A66ECD;
    -moz-box-shadow:inset 0px 0px 0px 3px  #A66ECD;
    box-shadow:inset 0px 0px 0px 3px  #A66ECD;

    outline: -3px solid #A66ECD;
    outline-offset: -3px;
}


.fifthTraitImage-status-available {
    background: #FFFFFF;
    padding:4px;
    border-radius: 20px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-size: 11px;
    line-height: 100%;
    text-align: center;
    margin: auto;
    width: 100px;
    text-transform: uppercase;

    color: #000000;
}
.fifthTraitImage-status-reserved {
    background: grey;
    padding:4px;
    border-radius: 20px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-size: 11px;
    line-height: 100%;
    text-align: center;
    margin: auto;
    width: 100px;
    text-transform: uppercase;

    color: #000000;
}
.fifthTraitImage-status-reserved-img {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    cursor: not-allowed;
}

.fifthTraitImage-name{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */
    display: flexbox;
    align-items: center;
    text-align: center;

    color: #FFFFFF;

    /* Purple Glow */
    text-shadow: 0px 0px 7px #674BA2;

}