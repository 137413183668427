.container {
    padding: 10px;
    background: black;
    height: auto;
  }
  
  .slick-slide img {
    margin: auto;
  }
  
  
  .variable-width .slick-slide p {
    background: blue;
    height: 100px;
    color: #fff;
    margin: 5px;
    line-height: 100px;
    text-align: center;
  }