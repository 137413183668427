.boonji-section{
    padding: 20px 0;
    display: flex;
    justify-content: center; 
    margin: 100px 0px;  
}

.boonji-section-container{
    display: flex;
    color:#fff;    
    flex-wrap: wrap;
    width: 100%;
    max-width: 1170px;
    padding: 0 50px;
}

.boonji-section-info{
    width: 50%;
    padding: 40px 0 0 0;
}

.boonji-section-image-container{
    width: 50%;
    text-align: left;
    padding-left: 100px !important;
}

.boonji-section img{
    width:382px;
}

@media screen and (max-width: 992px) {

    .boonji-section-image-container{
        width: 50%;
        text-align: left;
        padding-left: 5% !important;
    }

    .boonji-section img{
        width:90%;
    }
}

@media screen and (max-width: 768px) {

    .boonji-section{
        margin-top: 0px;
        padding: 0px;
    }

    .boonji-section-container{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        color:#fff;
        padding: 0px 0px;
    }

    .boonji-section-info{
        width: 100% !important;
        padding: 30px 0 0 0;
        text-align: center;
    }

    .boonji-section h2{
        font-size: 20px;
    }

    .boonji-section h3{
        text-align: center;
        padding: 0px 30px;
    }

    .boonji-section p{
        text-align: left;
        padding: 0px 30px;
        font-size: 14px;
        font-weight: 800;
    }

    .boonji-section-image-container{
        width: 100% !important;
        text-align: center;
        padding-left: 0px !important;
    }

    .boonji-section img{
        width:90% !important;
    }
} 

@media screen and (max-width: 425px) {

    .boonji-section{
        margin-bottom: 0px !important;
    }

    .boonji-section-info{
        padding: 40px 0px!important;
    }

    .boonji-section p{
        padding: 20px!important;
    }

    .boonji-section h3{
        padding: 0 15px!important;
        line-height: 20px;
    }
}
