.address-section{
    padding: 0 40px;
    max-width: 1170px;
    margin: auto;
}
.address-container{
    width:600px;
    text-align: center;
}

.address-container h2{
    width:100%;
    font-size: 32px;
}

.address-container-input{
    background: #FFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px;
    text-decoration: none; 
}

.address-container-input div{
    width: 100%;
    flex: 0 0 auto;
    border-style: none;
    border-width: 3px;
    border-color: #593b83;
    background-color: #fff;
    color: #000;
    font-size: 19px;
    text-align: center;
    letter-spacing: 1px; 
    padding: 16px;
}

.address-btn-container{
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


@media screen and (max-width: 600px) {

    .address-section{
        padding: 0 60px;
    }

    .address-container{
        width:100%;
        text-align: center;
        overflow: hidden;
    }

    .address-container h2{

        font-size: 20px;
    }

    .address-container-input div{
        width: 100%;
        font-size: 12px;
        overflow: scroll;
    }

    .address-btn-container{
        display: flex;
        flex-direction: column;
        margin-top: 40px;
            
    }

    .address-btn-container a{
        width:100%;
        margin-top: 20px;
    }    
}

@media screen and (max-width: 340px) {

    .address-section{
        padding: 0 15px;
    }
}