.logos-container{
    position:absolute;
    top:80px;
    right:30px;
    display: flex;
    flex-direction: column;
}

.logos-container a{
    width:20px;
    overflow: hidden;
    margin-bottom: 20px;
}

.logos-container img{
    width:100%;
}

.logos-container span{
    border:1px solid #333;
    background-color: aliceblue;
    color:#333;
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
    cursor:pointer;
}