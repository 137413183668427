.boonjiStudioTitle {
    padding: 25px;
}

.boonjiStudioTitle-title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    /* or 100px */
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
}

.boonjiStudioTitle-subTitle {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 100%;
    text-align: center;

    color: #FFFFFF;
}

.boonjiStudioTitle-description {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */
    text-align: center;

    color: #FFFFFF;
}

.boonjiStudio a {
    text-decoration: none;
    color:#DF7DA3;  
    font-weight: bold;
}