
.maxim-container{ 
    display: flex;
    max-width: 1170px;
    margin-top: 40px;
    margin-bottom: 40px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
}

.maxim-container-interior{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;

}

.maxim{  
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.maxim-h1{
    margin-top: 0px;
    margin-bottom: 0px;
    color: #df7da3;
    font-size: 60px;
    line-height: 100%;
}

.maxim-h2{
    margin-bottom: 0px;
    margin-left: 10px;
    color: #df7da3;
    font-size: 32px;
    line-height: 100%;
    font-weight: 400;
    line-height: 36px;
    margin-top: 6px;
}

.credits{
    text-align: center;
    margin-top: 10px;
}

.credits1{
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    font-style: italic;
}

.credits2{
    line-height: 25px;
    font-style: normal;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
}

@media screen and (max-width: 768px) {

    .maxim-container-interior{
        padding-left: 30px;
        padding-right: 30px;
    }

    .maxim{
        display:block;
    }
     
    .maxim-h1{
        width:90% !important;
    }

    .maxim-h2{
        width:90%;
        text-align: center;
    }
}

@media screen and (max-width: 425px) {

    .maxim-container-interior{
        padding-left: 15px;
        padding-right: 15px;
    }

    .maxim-h1{
        width:100% !important;
        text-align: center;
        margin-bottom: 20px;
        font-size: 40px;
    }

    .maxim-h2{
        width:100%;
        text-align: center;
        margin:0px 0px 20px 0px !important;
        font-size: 22px;
        line-height: 26px;
        padding: 0px !important;
    }

    .credits{
        width:100% !important;
    }

    .credits p{
        text-align: center;
        width:100% !important;
    }

}