.crew-section{
    text-align: center;
    width:100%;
    max-width: 100%;

    padding: 55px 20px;
    border-top: 10px solid #291743;
    border-bottom: 10px solid #1d0f31;
    background-image: linear-gradient(180deg, rgba(89, 59, 131, 0.8), #291743), url(https://assets.website-files.com/61defc8…/61defc8…_lore-bg.jpg);
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
}

.crew-section-container{
    max-width: 1170px;
    display: flex;
    flex-direction: column;
}

.crew-section h2{
    margin-top: 0px;
    color: #fff;
    font-size: 60px;
    line-height: 140%;
}

.crew-section p{
    margin-bottom: 80px;
}

.crew-section a{
    color:#fff;
}

.crew-info-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.crew-info-container>div{
    width:24%;
    height: auto;
    margin-bottom: 40px;
}

@media screen and (max-width: 992px) {

    .crew-info-container{
        flex-wrap: wrap;
    }

    .crew-info-container>div{        
        width:32% !important;
        margin-top: 20px;
    }
}

@media screen and (max-width: 768px) {

    .crew-info-container>div{       
        width:48% !important;
    }
}

@media screen and (max-width: 425px) {

    .crew-section-container h2{       
        font-size: 20px;
    }

    .crew-section-container p{       
        font-size: 14px;
        line-height: 18px;
    }

    .crew-info-container>div{       
        width:100% !important;
        min-height: 200px;
        height: 340px;
    }

    .crew-ui-img img{       
        max-width: 140px;
    }
}