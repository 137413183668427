.ownedBoonji {
    padding: 25px;
}

.ownedBoonji-title {
    font-family:'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
    /* or 38px */
    display: flex;
    align-items: center;

    color: #FFFFFF;
    padding-bottom: 30px;
}

.ownedBoonji-amount{
    font-family:'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 100%;
    /* or 38px */
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 5px;

    color: #DF7DA3;
}

.ownedBoonji a {
    text-decoration: none;
    color:#DF7DA3;  
}