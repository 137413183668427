.new-line {
    white-space: pre-line;
}

.phase-ui-top{
    display: flex;
    align-items: center;
    justify-content: center;
}

.phase-ui-left{
    width:50%;
    padding-left: 0px;
}

.phase-ui-right{
    width:50%;
}

.phase-ui-right img{
    width:100%;
}

.phase-title-planet{
    font-size: 80px !important;
    margin-top: 20px !important;
    line-height: 63px;
    letter-spacing: 20px;
    font-weight: 700;
}

.phase-ui-bottom{
    position: relative;
    top: -91px;
    padding: 98px 24px 24px;
    border-radius: 12px;
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(209, 120, 152, 0)), color-stop(52%, rgba(223, 125, 163, 0.56)), to(#5e4398));
    background-image: linear-gradient(180deg, rgba(209, 120, 152, 0), rgba(223, 125, 163, 0.56) 52%, #5e4398);
}


@media screen and (max-width: 425px) {

    .phase-ui-top{
        display: block;
    }

    .phase-ui-left{
        width:100%;
    }
    
    .phase-ui-right{
        width:100%;
        text-align: center;
    }
    
    .phase-ui-right img{
        width:100%;
    }

    .road-map-h2{

        font-size: 30px !important;
        margin-top: 0px !important;
        line-height: 50px;
        margin-bottom: 10px !important;
        padding-bottom: 0px !important;

    }

    .road-map-h3{
        margin-bottom: 0px !important;
    }

    .phase-ui-bottom{
        padding: 98px 15px;
    }

    .new-line{
        font-size: 14px;
        line-height: 120%;
    }
}

