.confirmation2 {
    display: block;
    margin-bottom: 150px;
}

.confirmation2-background{
    border: 2px solid #674BA2;
    background: url('../../../assets/new-twinkle.gif');
}

.confirmation2-title{
    font-family: 'DM Sans', sans-serif;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.confirmation2-approve{
    font-family: 'DM Sans', sans-serif;
    color: pink;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.confirmation2-mint{
    font-family: 'DM Sans', sans-serif;
    color: pink;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
}