.confirmation {
    background: black;
    background: url('../../../assets/new-twinkle.gif');
    border: 2px solid #674BA2;
}

.confirmation-title{
    font-family: 'DM Sans', sans-serif;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 80%;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 15px;
    
}

.confirmation-traits-title{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;
    /* or 26px */
    text-align: center;
    padding: 5px;
    color: #FFFFFF;
}

.confirmation-fifth-trait{
    padding-top: 10px;
    padding-bottom: 10px;
}

.confirmation-fifth-trait-text{
    padding: 5px;
    color: #fff;
    align-items: center;
    text-decoration: none;
    line-height: 10px;
    height: 100%;
    font-size: 12px;
    font-family: 'DM Sans', sans-serif;
}

.confirmation-boonjai{
    padding: 5px;
    color: #fff;
    align-items: center;
    text-decoration: none;
    line-height: 20px;
    height: 100%;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
}

.confirmation-actions{
    background: url('../../../assets/new-twinkle.gif');
}

.confirmation-invisible {
    display: none;
    visibility: hidden;
}