.link-btn{
    background-image: linear-gradient(180deg, #784fb1, #df7da3);
    display:inline-block;
    padding: 2px;
}

.link-btn div{
    background-color:#000;
    padding: 10px 20px;
}

.link-btn a{
    text-decoration: none;
    color:#fff;  
}

@media screen and (max-width: 768px) {
    .link-btn{        
        display:block;
    }
}

@media screen and (max-width: 425px) {
    .link-btn div{
        background-color:#000;
        padding: 10px 15px;
    }
}