.reel-container{
    padding-top: 100px;
    max-width: 1170px;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
}

.reel-container img{
    width:23%;
    border-radius: 12px;
}

@media screen and (max-width: 425px) {
    .reel-container{
        justify-content: center;
        margin: auto;
        flex-direction: column;
        padding: 50px 20px 0px;
    }
    
    .reel-container img{
        width:100%;
        border-radius: 12px;
        margin-bottom: 20px;
    }
}