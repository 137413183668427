.moonji-section{
    
}

.moonji-section-container{
    display: flex;
    padding: 50px;
    padding-top: 60px;
    padding-bottom: 30px;
    border-top: 10px solid #301e4b;
    border-bottom: 10px solid #3e2363;
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    max-width: 1170px;
}

.moonji-info{
    width:50%;
    padding-right: 20px;
}

.moonji-info p{
    font-weight: 400;
    font-size: 20px;
    line-height: 181%;
}

.moonji-images{
    width:50%;
    display:flex;
    flex-direction: column;
    text-align: center;
    padding: 0 0 0 10px;    
}

.moonji-images img{
    width: 97%;
    border-radius: 12px;
    margin-bottom: 25px;
    
}

@media screen and (max-width: 992px) {

    .moonji-info p{
        font-size: 17px;
    }

}

@media screen and (max-width: 768px) {

    .moonji-section-container{
        display: flex;
        flex-direction: column-reverse;
        padding: 30px;
    }

    .moonji-images{
        width:100%;
        display:flex;
        flex-direction: column;
        text-align: center;
        padding: 0 0 0 0px;    
    }

    .moonji-images>div:nth-child(2){
        display:none;
    }

    .moonji-images img{
        width:100% !important;
    }

    .moonji-info{
        width:100%;
        padding-right:0px;
    }

    .moonji-info h2{
        text-align: center;
        font-size: 20px;
    }

    .moonji-info h3{
        text-align: center;
    }
}

@media screen and (max-width: 425px) {

    .moonji-section-container{
        padding: 30px 15px;
    }

    .moonji-info p{
        font-size: 14px;
    }
}