.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  max-width: 1170px; 
}

.nav-menu {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.menu-icon {
  display: none;
}

.connect-wallet-container{

}

.connect-btn{
  background-image: linear-gradient(180deg, #784fb1, #df7da3);
  display:inline-block;
  padding: 2px;
  cursor:pointer;
}

.connect-btn button{
  background-color:#000;
  padding: 8px 14px;
  cursor:pointer;
  color:#fff;
  border:0px;
  font-size: 14px;
}

@media screen and (max-width: 992px) {
  
    .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    opacity: 0;
    transition: all 1.5s ease;
  }

  .nav-menu.active {
    background: #000;
    top: 80px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left:0;
    width:100%;
    font-size: 1.8rem;
    cursor: pointer;
    color:#fff;
    padding: 20px;
  }

  .menu-icon h2{
    margin-right: 15px;
    font-size: 14px;
    letter-spacing: 5px;
    text-transform: uppercase;
  }

  .menu-icon img{
    margin-top: -25px;
    width:25px;
  }
}