.purple-btn-rect-click{
    background-color: #593b83;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color:#fff;
    padding: 9px 15px;
    text-decoration: none;
    text-align: center;
    
    border-radius: 25px;

    min-width:100px;
    margin-left:10px;
    overflow:hidden;
}

.purple-btn-rect-click:hover {
    background-color: #784fb1;
    cursor: pointer;
}

.purple-btn-rect-click-disabled{
    background-color: grey;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color:#fff;
    padding: 9px 15px;
    text-decoration: none;
    text-align: center;
    
    border-radius: 25px;

    min-width:100px;
    margin-left:10px;
    overflow:hidden;
}
.purple-btn-rect-click-disabled:hover{
    background-color: gray;
    cursor:auto;
}