.boonjiPreview {
    /* right: 60px;
    top: 340px; */
    /* width: 36vw; */
    /* position: fixed; */

    position: -webkit-sticky;
    position: sticky;
    height: 10vh;
}

.boonjiPreview-box {
    border-radius: 10px;
    border: 2px solid #674BA2;

    box-shadow: 0px 0px 7px #674BA2;
}   

.boonjiPreview-title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    /* display: flex; */
    align-items: center;
    text-align: center;

    color: #FFFFFF;


}

.boonjiPreview-button {
    align-items: center;
    text-align: center;
    display: block;
    width: max-content;
}

.boonjiPreview-previewTitle {
    color: #fff;
    text-align: center;
    text-decoration: none;
    line-height: 20px;
    height: 100%;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
}

.selectedTraits-title {
    text-align: center;
    padding: 5px;
    color: #fff;
    text-decoration: none;
    line-height: 20px;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
}

.selectedBonjai-title {
    padding: 5px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
}

.boonjiPreview-background{
    background: #16011D;
    padding-top: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

