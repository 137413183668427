.boonjiStudio{
    background: url('../../assets/superBoonji/superBoonji_background.png'), linear-gradient(180deg, #6A1A62 0%, #530355 0.01%, #16011D 100%);
}


.estudio-container{
   display: block;
   margin: 0px auto 80px auto;
   max-width: 1200px;
}
.boonji-estudio-my-boonjai{
    width:100%;
    display: flex;
    justify-content: left;
    margin: 30px 0px;
}

.boonjiStudio-preview {
    top: 0;
    position: -webkit-sticky;
    position: sticky;
}

.boonjiStudio-previewMobile {
    top: 65%;
    background-color: #16011D;
    border-radius: 20px;
    margin-left: 55%;
    /* width: 250px; */
    /* position: fixed; */
    position: -webkit-sticky;
    position: sticky;
    z-index: 9999;
}

