.boonji-estudio-trait{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
    height: 200px;
}

.boonji-estudio-trait p{
    text-align: center;
    width:100%;
    margin: 10px 0px;
}
.boonji-estudio-trait-title{
    text-transform: uppercase;
    font-size: small;
}

.boonji-estudio-trait-name{
    text-transform: capitalize;
}
.boonji-estudio-trait-selected{
    background-color: #16011D;
    border-radius: 10px;
    width: 90%;
    text-align: center;
    
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    /* border-radius: 10px; */

    -webkit-box-shadow:inset 0px 0px 0px 3px  #A66ECD;
    -moz-box-shadow:inset 0px 0px 0px 3px  #A66ECD;
    box-shadow:inset 0px 0px 0px 3px  #A66ECD;

    outline-offset: -2px;
    outline: 2px solid #A66ECD;
    
}

.boonji-estudio-trait-selected-notOwned{
    background-color: #16011D;
    border-radius: 10px;
    width: 90%;
    text-align: center;
    
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    /* border-radius: 10px; */

    -webkit-box-shadow:inset 0px 0px 0px 3px  greenyellow;
    -moz-box-shadow:inset 0px 0px 0px 3px  greenyellow;
    box-shadow:inset 0px 0px 0px 3px  greenyellow;

    outline: 3px solid greenyellow;
    outline-offset: -3px;
    
}

.boonji-estudio-trait-unSelected {
    background-color: #ffffff;
    border-radius: 10px;
    width: 90%;
    text-align: center;
    
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    /* border-radius: 10px; */
}

.boonji-estudio-trait-bg-img:hover{
    cursor: pointer;
}

.boonji-estudio-trait-bg-img-loading:hover{
    cursor: auto
}