.boonjiCard {
    background-color: #16011D;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 171%;
    /* identical to box height, or 41px */

    text-align: center;

    color: #FFFFFF;
    border-radius: 5px;

}