.road-map-ul{
    list-style: none;
    padding-left:0px;
    margin-bottom: 60px;
    width:100%;
}

.road-map-ul li::before{
    content: "\2022";  
    color: #df7da3;
    font-size: 30px;
    font-weight: bold; 
    display: inline-block; 
    width: 30px;
    max-width: 30px;
}

.road-map-ul li{
    display: flex;
}
.road-map-ul li div{
    padding-top: 9px;
    width: 100%;
}

.road-map-ul li span{
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    text-decoration: none;
}

.road-map-ul li a{
    color: #df7da3;
    font-weight: 700;
    text-decoration: none;
}

.road-map-ul li a:hover{
    text-decoration: underline #df7da3;
}

/* @media screen and (max-width: 425px) {

    .phases-section{
        padding: 0px 15px;
    }

    .road-map-ul li span{
        font-size: 14px;
    }

} */