.brendan-section
{
    max-width: 1170px;
    margin: auto;
    display: flex;
}

.brendan-img-container{
    width:40%;
    overflow: hidden;
    padding: 0 20px;
}

.brendan-img-container img{
    width:100%;
    border-radius: 20px;
}

.brendan-info-container{
    width:60%;
    padding: 0 20px;
}

.brendan-info-container p{
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .brendan-links{
        width:130px;
    }
}

@media screen and (max-width: 425px) {

    .brendan-section
    {
        display: flex;
        flex-direction: column;
    }

    .brendan-img-container{
        width:100%;
        overflow: hidden;
        padding: 0 20px;
        margin-bottom: 40px;
    }
    
    .brendan-img-container img{
        width:100%;
        border-radius: 20px;
    }
    
    .brendan-info-container{
        width:100%;
        padding: 0 20px;
    }

    .brendan-info-container h2{
        text-align: center;
    }
    
    .brendan-info-container p{
        font-size: 14px;
    }

    .brendan-links{
        width:100%;
        text-align: center;
    }

}

