
.nav-links {
  color: #fff;
  align-items: center;
  text-decoration: none;
  line-height: 20px;
  height: 100%;
  font-size: 14px;
  font-family: 'DM Sans', sans-serif;
}

.nav-links:hover {
  color:#df7da3;
  transition: all 0.2s ease-out;
}

.link-selected{
  color:#df7da3;
}

@media screen and (max-width: 992px) {
  
  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }
}