.boonji-trait-line{
    width:100%;
    display: flex;
    justify-content: space-between;
}

.boonji-trait-line-item {
    padding-left: 5px;
    padding-right: 5px;
}

