.selectedBoonjai {
    border-radius: 10px;
}

.selectedBoonjai-name{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 171%;
    /* identical to box height, or 26px */
    text-align: center;

    color: #FFFFFF;
}


.selectedBoonjai-boonji{
    border-radius: 10px;
    width: auto;
    height: 70px;
    background: #292929;
}

.selectedBoonjai img {
    border-radius: 10px;
    width: auto;
    /* height: 84px; */
    height: 100%;
    
}

.selectedBoonjai-selected{
    /* border: 2px solid palevioletred; */
    outline-offset: -2px;
    outline: 2px solid palevioletred;
}
.selectedBoonjai-selected-notOwned{
    /* border: 2px solid greenyellow; */
    outline-offset: -2px;
    outline: 2px solid greenyellow;
}
.selectedBoonjai-notSelected{
    /* border: 2px dotted palevioletred; */
    outline-offset: -2px;
    outline: 2px solid gray;
}