.tools-section{
    display:block;
    padding: 0 100px;
    text-align: center;
    max-width: 1170px;
    margin: auto;
}

.tools-section p{
    margin-bottom: 30px;
}

.tools-links>div{
    margin: 20px 15px;
}

@media screen and (max-width: 425px) {

    .tools-section{
        padding: 0 15px;
    }

    .tools-section h2{
        font-size: 20px;
    }

    .tools-section p{
        font-size: 14px;
    }

    .tools-links{
        width: 200px;
        margin:auto;    
    }
}