.purple-btn-rect{
    background-color: #593b83;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color:#fff;
    padding: 9px 15px;
    text-decoration: none;
}

.purple-btn-rect:hover {
    background-color: #784fb1;
}