.fifthTrait {
    padding: 20px;
}


.fifthTrait-title{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.fifthTrait-subTitle{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */
    display: flex;
    align-items: center;
    padding-bottom: 30px;

    color: #DF7DA3;
}

.fifthTrait-type {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding-bottom: 20px;

    color: #FFFFFF;
}

.fifthTrait-signature {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding-bottom: 20px;

    color: #DF7DA3;
}

.fifthTrait-carousel {
    padding-left: 10px;
}