.confirmation3{
    padding: 5px;
}

.confirmation3 a{
    text-decoration: none;
    color: violet;  
}

.confirmation3-description{
    font-family: 'DM Sans', sans-serif;
    color: pink;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 80%;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 5px;
}