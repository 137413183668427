.boonji-img{
    text-align: center;
    max-width: 1170px;
    margin: 0px auto 100px;
}

.boonji-img img{
    width:20%;
}

@media screen and (max-width: 425px) {
    .boonji-img img{
        width:54%;
    }
}