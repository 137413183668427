@import url('https://fonts.googleapis.com/css2?family=DM+Sans&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'DM Sans', sans-serif;
  }

body{
  background-color: #000;
  background-position: 50% 0%;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: fixed;
  font-family: 'DM Sans', sans-serif;
}

section{
  margin-top: 100px;
  margin-bottom: 100px;
  width:100%;
  display: flex;
  justify-content: center;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin-bottom: 10px;
}

h2{
  font-size: 32px;
  color:#fff;
  margin-bottom: 25px;
}

h3{
  margin-bottom: 20px;
  color: #df7da3;
  font-size: 12px;
  text-align: left;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 25px;
}

p{
  margin-bottom: 20px;
  color: #fff;
  font-size: 16px;
  line-height: 160%;
  margin-top: 0px;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 1px;
  text-indent: 0px;
  white-space: normal;
}
 
 