@import url(https://fonts.googleapis.com/css2?family=DM+Sans&display=swap);

.nav-links {
  color: #fff;
  align-items: center;
  text-decoration: none;
  line-height: 20px;
  height: 100%;
  font-size: 14px;
  font-family: 'DM Sans', sans-serif;
}

.nav-links:hover {
  color:#df7da3;
  transition: all 0.2s ease-out;
}

.link-selected{
  color:#df7da3;
}

@media screen and (max-width: 992px) {
  
  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
    color: #fff;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }
}
.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  max-width: 1170px; 
}

.nav-menu {
  display:flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

.menu-icon {
  display: none;
}

.connect-wallet-container{

}

.connect-btn{
  background-image: linear-gradient(180deg, #784fb1, #df7da3);
  display:inline-block;
  padding: 2px;
  cursor:pointer;
}

.connect-btn button{
  background-color:#000;
  padding: 8px 14px;
  cursor:pointer;
  color:#fff;
  border:0px;
  font-size: 14px;
}

@media screen and (max-width: 992px) {
  
    .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 80px;
    opacity: 0;
    transition: all 1.5s ease;
  }

  .nav-menu.active {
    background: #000;
    top: 80px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(25%, 50%);
            transform: translate(25%, 50%);
  }

  .menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left:0;
    width:100%;
    font-size: 1.8rem;
    cursor: pointer;
    color:#fff;
    padding: 20px;
  }

  .menu-icon h2{
    margin-right: 15px;
    font-size: 14px;
    letter-spacing: 5px;
    text-transform: uppercase;
  }

  .menu-icon img{
    margin-top: -25px;
    width:25px;
  }
}
.footer-section{
    color:#fff;
    padding: 0 0 200px 0;
    margin-bottom:0px !important;
    margin-top: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.footer-img-container{

    padding: 0px 0px;
    text-align: center;
}

.footer-img-container img{
    width:25%;
}

.footer-logos-container{
    display: flex;
    justify-content: center;
}

.footer-logos-container img{
    width:25px;
    margin: auto 10px;
}

.footer-credits-container{
    display: flex;
    justify-content: center;
    width:100%;
    font-size: 12px;
    padding: 40px 0px 0px 0px;
}

.footer-credits-a{
    width:50%;
    text-align: right;
    padding-right: 10px;
}

.footer-credits-a a{
    text-decoration: none;
    color:#fff;
}

.footer-powered{
    width:50%;
    display: flex;
    text-align: left;
}

.footer-powered img{
    width:80px;
}

@media screen and (max-width: 992px) {

    .footer-img-container img{
        width:40%;
    }
}

@media screen and (max-width: 425px) {

    .footer-img-container img{
        margin-top: 50px;
        width:70%;
    }

    .footer-credits-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width:100%;
        font-size: 12px;
        padding: 40px 0px 0px 0px;
    }

    .footer-credits-a{
        width:100%;
        text-align: center;
        
    }
    
    .footer-powered{
        margin-top: 10px;
        width:100%;
        text-align: center !important;
        display: flex;
        justify-content: center;
    }

    .footer-powered img{
        width:100px;
        margin-left: 5px;
    }

    .footer-powered div{
        padding-top: 4px;
    }
}
.boonji-section{
    padding: 20px 0;
    display: flex;
    justify-content: center; 
    margin: 100px 0px;  
}

.boonji-section-container{
    display: flex;
    color:#fff;    
    flex-wrap: wrap;
    width: 100%;
    max-width: 1170px;
    padding: 0 50px;
}

.boonji-section-info{
    width: 50%;
    padding: 40px 0 0 0;
}

.boonji-section-image-container{
    width: 50%;
    text-align: left;
    padding-left: 100px !important;
}

.boonji-section img{
    width:382px;
}

@media screen and (max-width: 992px) {

    .boonji-section-image-container{
        width: 50%;
        text-align: left;
        padding-left: 5% !important;
    }

    .boonji-section img{
        width:90%;
    }
}

@media screen and (max-width: 768px) {

    .boonji-section{
        margin-top: 0px;
        padding: 0px;
    }

    .boonji-section-container{
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        color:#fff;
        padding: 0px 0px;
    }

    .boonji-section-info{
        width: 100% !important;
        padding: 30px 0 0 0;
        text-align: center;
    }

    .boonji-section h2{
        font-size: 20px;
    }

    .boonji-section h3{
        text-align: center;
        padding: 0px 30px;
    }

    .boonji-section p{
        text-align: left;
        padding: 0px 30px;
        font-size: 14px;
        font-weight: 800;
    }

    .boonji-section-image-container{
        width: 100% !important;
        text-align: center;
        padding-left: 0px !important;
    }

    .boonji-section img{
        width:90% !important;
    }
} 

@media screen and (max-width: 425px) {

    .boonji-section{
        margin-bottom: 0px !important;
    }

    .boonji-section-info{
        padding: 40px 0px!important;
    }

    .boonji-section p{
        padding: 20px!important;
    }

    .boonji-section h3{
        padding: 0 15px!important;
        line-height: 20px;
    }
}

.specs-section{
   
}

.specs-section-container{
    display: flex;
    color:#fff;
    padding: 50px;
    max-width: 1170px;
}

.specs-section-container>div{
    width: 50%;
    padding: 40px 0 0 0;
}

.specs-section-container>div:first-child{
    padding: 40px 20px 0 0;
}

.specs-section-info-container{
    text-align: left;
}

.specs-section-info-container>div:first-child{
    text-align: center;
    padding: 30px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-image: linear-gradient(233deg, rgba(223, 125, 163, 0), rgba(223, 125, 163, 0.53) 45%, #a66ecd);
    background-image: linear-gradient(210deg, rgba(192, 117, 187, 0), rgba(192, 117, 187, 0.46) 46%, #a66ecd);
}

.specs-section-info-container p{
    text-align: left;
    padding: 0px 10px;
}

.specs-section-info-container a{
    color:#fff;
}

.specs-section-btns-container{
    display: flex;
}

.specs-btn-pink{ 
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 0px;
    background-color: #df7da3;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    cursor: pointer;
}

.specs-btn-pink:hover{
    background-color: #784fb1; 
}

.specs-btn-purple{
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 20px;
    background-color: #291743;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
}

.specs-btn-purple:hover{
    background-color: #784fb1; 
}

@media screen and (max-width: 768px) {

    .specs-section-container{
 
        padding: 30px !important;
    }

}

@media screen and (max-width: 425px) {

    .specs-section-container{
        display: flex;
        flex-direction: column-reverse;
        padding: 0px !important;
    }

    .specs-section-info-container{
        text-align: center;
        width:100% !important;
        padding: 0px 15px !important;
    }

    .specs-section-info-container p{
        text-align: center;
    }

    .specs-section-desc{
        width: 100% !important;
        padding: 30px 15px !important;
    }

    .specs-section-desc h2{
        text-align: center;
    }

    .specs-section-desc h3{
        text-align: center;
    }
}




.communji-section{
   
}

.communji-section-container{

    display: flex;
    padding: 0 50px;
    max-width: 1170px;

}

.communji-info{
    width:38%;
    padding-right: 20px;
}

.communji-info>div{
    padding: 20px 30px 20px 30px;
    border-radius: 12px;
    background-color: #593b83;
    min-height: 400px;
    width:96%;
}

.communji-info p{
    font-size: 14px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.communji-img{
    width:62%;
    
}

.communji-img img{
    width:100%;
    border-radius: 12px;

}

@media screen and (max-width: 992px) {

    .communji-section-container{
        display: block;
        padding: 0 30px;
    }

    .communji-info{
        width:100%;
        padding: 0px !important;
    }

    .communji-info>div{
        width: 100%;
        margin-bottom: 20px;
    }

    .communji-img{
        width:100%;
        height: auto !important;
    }
}

@media screen and (max-width: 425px) {

    .communji-section-container{
        display: block;
        padding: 0 15px;
    }

    .communji-info>div{
        padding: 20px 20px;
    }

    .communji-info>div h2{
        text-align: center;
        font-size: 20px;
    }
}
.moonji-section{
    
}

.moonji-section-container{
    display: flex;
    padding: 50px;
    padding-top: 60px;
    padding-bottom: 30px;
    border-top: 10px solid #301e4b;
    border-bottom: 10px solid #3e2363;
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
    max-width: 1170px;
}

.moonji-info{
    width:50%;
    padding-right: 20px;
}

.moonji-info p{
    font-weight: 400;
    font-size: 20px;
    line-height: 181%;
}

.moonji-images{
    width:50%;
    display:flex;
    flex-direction: column;
    text-align: center;
    padding: 0 0 0 10px;    
}

.moonji-images img{
    width: 97%;
    border-radius: 12px;
    margin-bottom: 25px;
    
}

@media screen and (max-width: 992px) {

    .moonji-info p{
        font-size: 17px;
    }

}

@media screen and (max-width: 768px) {

    .moonji-section-container{
        display: flex;
        flex-direction: column-reverse;
        padding: 30px;
    }

    .moonji-images{
        width:100%;
        display:flex;
        flex-direction: column;
        text-align: center;
        padding: 0 0 0 0px;    
    }

    .moonji-images>div:nth-child(2){
        display:none;
    }

    .moonji-images img{
        width:100% !important;
    }

    .moonji-info{
        width:100%;
        padding-right:0px;
    }

    .moonji-info h2{
        text-align: center;
        font-size: 20px;
    }

    .moonji-info h3{
        text-align: center;
    }
}

@media screen and (max-width: 425px) {

    .moonji-section-container{
        padding: 30px 15px;
    }

    .moonji-info p{
        font-size: 14px;
    }
}

.maxim-container{ 
    display: flex;
    max-width: 1170px;
    margin-top: 40px;
    margin-bottom: 40px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex: 1 1;
}

.maxim-container-interior{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

}

.maxim{
    display: flex;
    align-items: center;
}

.maxim-h1{
    margin-top: 0px;
    margin-bottom: 0px;
    color: #df7da3;
    font-size: 60px;
    line-height: 100%;
}

.maxim-h2{
    margin-bottom: 0px;
    margin-left: 10px;
    color: #df7da3;
    font-size: 32px;
    line-height: 100%;
    font-weight: 400;
    line-height: 36px;
    margin-top: 6px;
}

.credits{
    text-align: center;
    margin-top: 10px;
}

.credits1{
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    font-style: italic;
}

.credits2{
    line-height: 25px;
    font-style: normal;
    font-weight: 500;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
}

@media screen and (max-width: 768px) {

    .maxim-container-interior{
        padding-left: 30px;
        padding-right: 30px;
    }

    .maxim{
        display:block;
    }
     
    .maxim-h1{
        width:90% !important;
    }

    .maxim-h2{
        width:90%;
        text-align: center;
    }
}

@media screen and (max-width: 425px) {

    .maxim-container-interior{
        padding-left: 15px;
        padding-right: 15px;
    }

    .maxim-h1{
        width:100% !important;
        text-align: center;
        margin-bottom: 20px;
        font-size: 40px;
    }

    .maxim-h2{
        width:100%;
        text-align: center;
        margin:0px 0px 20px 0px !important;
        font-size: 22px;
        line-height: 26px;
        padding: 0px !important;
    }

    .credits{
        width:100% !important;
    }

    .credits p{
        text-align: center;
        width:100% !important;
    }

}
.road-map-section{
    display:block;
    text-align: center;
    padding: 0 40px;
    max-width: 1170px;
    margin: auto;
}

.road-map-section h2{
    font-size: 60px;
    font-weight: bold;
    text-align: center;
}

.road-map-section h3{
    text-align: center;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 4px;
}

.road-map-p{
    margin-bottom: 20px;
    color: #fff;
    font-size: 20px;
    line-height: 130%;
    font-weight: 700;
    text-align: left;
    font-weight: 700;
}

.quote-container{
    padding: 0 21%;
    text-align: justify;
}

.quote{
    border-left: 5px solid #df7da3;
    color: #fff;
    font-size: 18px;
    line-height: 26px;
    text-align: justify;
    letter-spacing: 1px;
    text-transform: none;
    font-size: bold;
    padding: 10px 20px;
    margin-top: 40px;
    margin-bottom: 0px;
}

.author{
    text-align: right;
    margin-top: 0px;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
}

@media screen and (max-width: 992px) {
    .road-map-p{
        font-size: 18px !important;
    }

    .quote-container{
        padding: 0 6%;
    }

    .quote{
        font-size: 16px;
        line-height: 120%;
    }
}

@media screen and (max-width: 768px) {

    .road-map-section{
        padding: 0 30px;
    }
}

@media screen and (max-width: 425px) {

    .road-map-section h2{
        font-size: 40px;
        padding: 0px 50px;
        line-height: 45px;
    }

    .road-map-section h3{
        line-height: 25px;
        padding: 0px;
        font-size: 14px;
    }

    .road-map-section{
        padding: 0 15px;
    }

    .quote-container{
        padding: 0 5px;
        text-align: justify;
    }

    .road-map-h2{
        font-size: 20px !important;
        letter-spacing: 6px !important;
        font-weight: bold;
        text-align: center;
    }

}
.road-map-ul{
    list-style: none;
    padding-left:0px;
    margin-bottom: 60px;
    width:100%;
}

.road-map-ul li::before{
    content: "\2022";  
    color: #df7da3;
    font-size: 30px;
    font-weight: bold; 
    display: inline-block; 
    width: 30px;
    max-width: 30px;
}

.road-map-ul li{
    display: flex;
}
.road-map-ul li div{
    padding-top: 9px;
    width: 100%;
}

.road-map-ul li span{
    color: #fff;
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    text-decoration: none;
}

.road-map-ul li a{
    color: #df7da3;
    font-weight: 700;
    text-decoration: none;
}

.road-map-ul li a:hover{
    -webkit-text-decoration: underline #df7da3;
            text-decoration: underline #df7da3;
}

/* @media screen and (max-width: 425px) {

    .phases-section{
        padding: 0px 15px;
    }

    .road-map-ul li span{
        font-size: 14px;
    }

} */
.new-line {
    white-space: pre-line;
}

.phase-ui-top{
    display: flex;
    align-items: center;
    justify-content: center;
}

.phase-ui-left{
    width:50%;
    padding-left: 0px;
}

.phase-ui-right{
    width:50%;
}

.phase-ui-right img{
    width:100%;
}

.phase-title-planet{
    font-size: 80px !important;
    margin-top: 20px !important;
    line-height: 63px;
    letter-spacing: 20px;
    font-weight: 700;
}

.phase-ui-bottom{
    position: relative;
    top: -91px;
    padding: 98px 24px 24px;
    border-radius: 12px;
    background-image: linear-gradient(180deg, rgba(209, 120, 152, 0), rgba(223, 125, 163, 0.56) 52%, #5e4398);
}


@media screen and (max-width: 425px) {

    .phase-ui-top{
        display: block;
    }

    .phase-ui-left{
        width:100%;
    }
    
    .phase-ui-right{
        width:100%;
        text-align: center;
    }
    
    .phase-ui-right img{
        width:100%;
    }

    .road-map-h2{

        font-size: 30px !important;
        margin-top: 0px !important;
        line-height: 50px;
        margin-bottom: 10px !important;
        padding-bottom: 0px !important;

    }

    .road-map-h3{
        margin-bottom: 0px !important;
    }

    .phase-ui-bottom{
        padding: 98px 15px;
    }

    .new-line{
        font-size: 14px;
        line-height: 120%;
    }
}


.phases-section{
    padding: 0 40px;
    color:#fff;
    display: block;
    max-width: 1170px;
    margin: auto;
}

.roadmap-phase-number{
    margin-bottom: 10px;
    color: #784fb1;
    line-height: 100%;
    font-weight: 700;
    text-align: left;
    letter-spacing: 2px;
    text-transform: uppercase;
}

.road-map-h3{
    color: #df7da3;
    font-size: 24px;
    text-align: left;
    letter-spacing: 6px;
    text-transform: uppercase;
    margin-bottom: 10px;
}

.road-map-h2{
    font-size: 55px;
    text-align: left;
    margin-top: 0px;
    font-weight: bold;
    letter-spacing: 20px;
}

@media screen and (max-width: 992px) {
    .road-map-h2{
        font-size: 40px;
        letter-spacing: 14px;
    }
}

@media screen and (max-width: 768px) {

    .phases-section{
        padding: 0px 30px;
    }

    .roadmap-phase-number{
        text-align: center;
    }

    .road-map-h3{
        text-align: center;
        font-size: 13px;
    }

    .road-map-h2{
        font-size: 40px;
        text-align: center;
        letter-spacing: 12px;
    }
}

@media screen and (max-width: 425px) {

    .phases-section{
        padding: 0px 15px;
    }
}
.link-btn{
    background-image: linear-gradient(180deg, #784fb1, #df7da3);
    display:inline-block;
    padding: 2px;
}

.link-btn div{
    background-color:#000;
    padding: 10px 20px;
}

.link-btn a{
    text-decoration: none;
    color:#fff;  
}

@media screen and (max-width: 768px) {
    .link-btn{        
        display:block;
    }
}

@media screen and (max-width: 425px) {
    .link-btn div{
        background-color:#000;
        padding: 10px 15px;
    }
}
.tools-section{
    display:block;
    padding: 0 100px;
    text-align: center;
    max-width: 1170px;
    margin: auto;
}

.tools-section p{
    margin-bottom: 30px;
}

.tools-links>div{
    margin: 20px 15px;
}

@media screen and (max-width: 425px) {

    .tools-section{
        padding: 0 15px;
    }

    .tools-section h2{
        font-size: 20px;
    }

    .tools-section p{
        font-size: 14px;
    }

    .tools-links{
        width: 200px;
        margin:auto;    
    }
}
.crew-ui-container{
    display:flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.crew-ui-img img{
    width:100%;
    max-width: 190px;
    border-style: solid;
    border-width: 5px;
    border-color: #593b83;
    border-radius: 300px;
    border-radius:300px;
}

.crew-ui-name{
    margin-bottom: 5px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
}

.crew-ui-position{
    margin-bottom: 5px;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    margin-top: 0;
}

.crew-ui-social{
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.crew-ui-social a{
    text-decoration: none;
    margin-left: 5px;
}

.crew-ui-social span{
    cursor: pointer;
}

.crew-ui-social img{
    margin-top: 4px;
}

.crew-ui-popup{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    padding-top: 10px;
    padding-right: 8px;
    padding-left: 8px;
    align-items: center;
    background-color: rgba(41, 23, 67, 0.95);
    display:flex;
    flex-direction: column;
    border-radius: 20px;
    height: 100%;
}

.crew-ui-popup-x-div{
    width:100%;
    text-align: right;
}

.crew-ui-popup-x-div img{
    width:15px;
    opacity: 0.4;
    cursor: pointer;
}

.crew-ui-popup-x-div img:hover{
    opacity: 1;
}

.crew-ui-popup h4{
    margin-top: 10px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 14px;
    line-height: 130%;
    font-weight: 700;
}

.crew-ui-popup-position{
    color: #fff;
    font-size: 12px;
    line-height: 130%;
}

.crew-ui-popup-divider{
    width: 100px;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #784fb1;
}

.crew-ui-popup-p{
    color: #fff;
    font-size: 12px !important;
    margin-bottom: 10px;
    line-height: 120%;
    padding: 0px;
}

.crew-section{
    text-align: center;
    width:100%;
    max-width: 100%;

    padding: 55px 20px;
    border-top: 10px solid #291743;
    border-bottom: 10px solid #1d0f31;
    background-image: linear-gradient(180deg, rgba(89, 59, 131, 0.8), #291743), url(https://assets.website-files.com/61defc8…/61defc8…_lore-bg.jpg);
    background-position: 0px 0px, 50% 50%;
    background-size: auto, cover;
}

.crew-section-container{
    max-width: 1170px;
    display: flex;
    flex-direction: column;
}

.crew-section h2{
    margin-top: 0px;
    color: #fff;
    font-size: 60px;
    line-height: 140%;
}

.crew-section p{
    margin-bottom: 80px;
}

.crew-section a{
    color:#fff;
}

.crew-info-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.crew-info-container>div{
    width:24%;
    height: auto;
    margin-bottom: 40px;
}

@media screen and (max-width: 992px) {

    .crew-info-container{
        flex-wrap: wrap;
    }

    .crew-info-container>div{        
        width:32% !important;
        margin-top: 20px;
    }
}

@media screen and (max-width: 768px) {

    .crew-info-container>div{       
        width:48% !important;
    }
}

@media screen and (max-width: 425px) {

    .crew-section-container h2{       
        font-size: 20px;
    }

    .crew-section-container p{       
        font-size: 14px;
        line-height: 18px;
    }

    .crew-info-container>div{       
        width:100% !important;
        min-height: 200px;
        height: 340px;
    }

    .crew-ui-img img{       
        max-width: 140px;
    }
}
.purple-btn-rect{
    background-color: #593b83;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color:#fff;
    padding: 9px 15px;
    text-decoration: none;
}

.purple-btn-rect:hover {
    background-color: #784fb1;
}
.address-section{
    padding: 0 40px;
    max-width: 1170px;
    margin: auto;
}
.address-container{
    width:600px;
    text-align: center;
}

.address-container h2{
    width:100%;
    font-size: 32px;
}

.address-container-input{
    background: #FFFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 14px;
    text-decoration: none; 
}

.address-container-input div{
    width: 100%;
    flex: 0 0 auto;
    border-style: none;
    border-width: 3px;
    border-color: #593b83;
    background-color: #fff;
    color: #000;
    font-size: 19px;
    text-align: center;
    letter-spacing: 1px; 
    padding: 16px;
}

.address-btn-container{
    height: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}


@media screen and (max-width: 600px) {

    .address-section{
        padding: 0 60px;
    }

    .address-container{
        width:100%;
        text-align: center;
        overflow: hidden;
    }

    .address-container h2{

        font-size: 20px;
    }

    .address-container-input div{
        width: 100%;
        font-size: 12px;
        overflow: scroll;
    }

    .address-btn-container{
        display: flex;
        flex-direction: column;
        margin-top: 40px;
            
    }

    .address-btn-container a{
        width:100%;
        margin-top: 20px;
    }    
}

@media screen and (max-width: 340px) {

    .address-section{
        padding: 0 15px;
    }
}
.journey-container{
    margin: auto;
}
.general-container{
    width:100%;
    display: flex;
    justify-content: center;
    
}

.general-container-int{
    width:100%;
}

.boonji-img{
    text-align: center;
    max-width: 1170px;
    margin: 0px auto 100px;
}

.boonji-img img{
    width:20%;
}

@media screen and (max-width: 425px) {
    .boonji-img img{
        width:54%;
    }
}
.brendan-section
{
    max-width: 1170px;
    margin: auto;
    display: flex;
}

.brendan-img-container{
    width:40%;
    overflow: hidden;
    padding: 0 20px;
}

.brendan-img-container img{
    width:100%;
    border-radius: 20px;
}

.brendan-info-container{
    width:60%;
    padding: 0 20px;
}

.brendan-info-container p{
    font-size: 14px;
}

@media screen and (max-width: 768px) {
    .brendan-links{
        width:130px;
    }
}

@media screen and (max-width: 425px) {

    .brendan-section
    {
        display: flex;
        flex-direction: column;
    }

    .brendan-img-container{
        width:100%;
        overflow: hidden;
        padding: 0 20px;
        margin-bottom: 40px;
    }
    
    .brendan-img-container img{
        width:100%;
        border-radius: 20px;
    }
    
    .brendan-info-container{
        width:100%;
        padding: 0 20px;
    }

    .brendan-info-container h2{
        text-align: center;
    }
    
    .brendan-info-container p{
        font-size: 14px;
    }

    .brendan-links{
        width:100%;
        text-align: center;
    }

}


.reel-container{
    padding-top: 100px;
    max-width: 1170px;
    margin: auto;
    display: flex;
    justify-content: space-evenly;
}

.reel-container img{
    width:23%;
    border-radius: 12px;
}

@media screen and (max-width: 425px) {
    .reel-container{
        justify-content: center;
        margin: auto;
        flex-direction: column;
        padding: 50px 20px 0px;
    }
    
    .reel-container img{
        width:100%;
        border-radius: 12px;
        margin-bottom: 20px;
    }
}
.bio-section{
    color:#fff;
    display: flex;
    flex-direction: column;
    margin-top: 30px !important;
}
.logos-container{
    position:absolute;
    top:80px;
    right:30px;
    display: flex;
    flex-direction: column;
}

.logos-container a{
    width:20px;
    overflow: hidden;
    margin-bottom: 20px;
}

.logos-container img{
    width:100%;
}

.logos-container span{
    border:1px solid #333;
    background-color: aliceblue;
    color:#333;
    padding: 4px;
    border-radius: 4px;
    font-size: 12px;
    cursor:pointer;
}
.selectedBoonjai {
    border-radius: 10px;
}

.selectedBoonjai-name{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 171%;
    /* identical to box height, or 26px */
    text-align: center;

    color: #FFFFFF;
}


.selectedBoonjai-boonji{
    border-radius: 10px;
    width: auto;
    height: 70px;
    background: #292929;
}

.selectedBoonjai img {
    border-radius: 10px;
    width: auto;
    /* height: 84px; */
    height: 100%;
    
}

.selectedBoonjai-selected{
    /* border: 2px solid palevioletred; */
    outline-offset: -2px;
    outline: 2px solid palevioletred;
}
.selectedBoonjai-selected-notOwned{
    /* border: 2px solid greenyellow; */
    outline-offset: -2px;
    outline: 2px solid greenyellow;
}
.selectedBoonjai-notSelected{
    /* border: 2px dotted palevioletred; */
    outline-offset: -2px;
    outline: 2px solid gray;
}
.selectedTraits {
    border-radius: 10px;
}

.selectedTraits-trait{
    border-radius: 10px;
    width: auto;
    height: 70px;
    background: #FFFFFF;
}

.selectedTraits-trait:hover{
    cursor: pointer;
}

.selectedTraits img {
    border-radius: 10px;
    width: auto;
    height: 100%;
}

.selectedTraits-notSelected{
    /* border: 2px dotted palevioletred; */
    outline-offset: -2px;
    outline: 2px solid gray;
}

.selectedTraits-selected{
    /* border: 2px solid palevioletred; */
    outline-offset: -2px;
    outline: 2px solid palevioletred;
}

.selectedTraits-selected-notOwned{
    /* border: 2px solid greenyellow; */
    outline-offset: -2px;
    outline: 2px solid greenyellow;
}

.selectedTraits-type {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 171%;
    /* identical to box height, or 26px */
    text-align: center;

    color: #FFFFFF;
}
.preview-fifthTrait {
    border-radius: 10px;
}
.preview-fifthTrait-title {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 76%;
    /* or 18px */

    text-transform: uppercase;
    color: #FFFFFF;
}

.preview-fifthTrait-trait{
    border-radius: 10px;
    width: 129;
    height: 85px;
    background: #292929;
}

.preview-fifthTrait img {
    border-radius: 10px;
    width: 129;
    height: 85px;
 
}

.preview-fifthTrait:hover{
    cursor: pointer;
}

.preview-fifthTrait-selected{
    outline-offset: -2px;
    outline: 2px solid palevioletred;
}

.preview-fifthTrait-Notselected{
    outline-offset: -2px;
    outline: 2px solid gray;
}
.preview {
    /* margin-top: -32px; */
    /* margin-left: 25px; */
    /* margin-bottom: -35px;  */
    
}

.preview svg {
    justify-content: center;
    align-items: center;
    height: 41vh;
    width: 100%;
}

.preview image {
    height: 41vh;
    width: 100%;
}
.preview-hover {
    cursor: pointer;
}
/* .preview-title {
    padding: 1px;
    color: #fff;
    align-items: center;
    text-decoration: none;
    line-height: 20px;
    height: 100%;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
} */


.maxPreview {
    background: url(/static/media/new-twinkle.661ae23e.gif);
}

.maxPreview-menu {
    background: #A66ECD;
}

.boonjiPreview {
    /* right: 60px;
    top: 340px; */
    /* width: 36vw; */
    /* position: fixed; */

    position: -webkit-sticky;
    position: sticky;
    height: 10vh;
}

.boonjiPreview-box {
    border-radius: 10px;
    border: 2px solid #674BA2;

    box-shadow: 0px 0px 7px #674BA2;
}   

.boonjiPreview-title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    /* display: flex; */
    align-items: center;
    text-align: center;

    color: #FFFFFF;


}

.boonjiPreview-button {
    align-items: center;
    text-align: center;
    display: block;
    width: -webkit-max-content;
    width: max-content;
}

.boonjiPreview-previewTitle {
    color: #fff;
    text-align: center;
    text-decoration: none;
    line-height: 20px;
    height: 100%;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
}

.selectedTraits-title {
    text-align: center;
    padding: 5px;
    color: #fff;
    text-decoration: none;
    line-height: 20px;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
}

.selectedBonjai-title {
    padding: 5px;
    color: #fff;
    text-decoration: none;
    text-align: center;
    line-height: 20px;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
}

.boonjiPreview-background{
    background: #16011D;
    padding-top: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}


.boonji-estudio-trait{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    align-items: center;
    height: 200px;
}

.boonji-estudio-trait p{
    text-align: center;
    width:100%;
    margin: 10px 0px;
}
.boonji-estudio-trait-title{
    text-transform: uppercase;
    font-size: small;
}

.boonji-estudio-trait-name{
    text-transform: capitalize;
}
.boonji-estudio-trait-selected{
    background-color: #16011D;
    border-radius: 10px;
    width: 90%;
    text-align: center;
    
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    /* border-radius: 10px; */
    box-shadow:inset 0px 0px 0px 3px  #A66ECD;

    outline-offset: -2px;
    outline: 2px solid #A66ECD;
    
}

.boonji-estudio-trait-selected-notOwned{
    background-color: #16011D;
    border-radius: 10px;
    width: 90%;
    text-align: center;
    
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    /* border-radius: 10px; */
    box-shadow:inset 0px 0px 0px 3px  greenyellow;

    outline: 3px solid greenyellow;
    outline-offset: -3px;
    
}

.boonji-estudio-trait-unSelected {
    background-color: #ffffff;
    border-radius: 10px;
    width: 90%;
    text-align: center;
    
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    /* border-radius: 10px; */
}

.boonji-estudio-trait-bg-img:hover{
    cursor: pointer;
}

.boonji-estudio-trait-bg-img-loading:hover{
    cursor: auto
}
.boonji-trait-line{
    width:100%;
    display: flex;
    justify-content: space-between;
}

.boonji-trait-line-item {
    padding-left: 5px;
    padding-right: 5px;
}


.boonji-main{
    width:100%;
    position:relative;
    height: 200px;
    overflow: hidden;
    text-align: center;
}

.boonji-main-img{
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.boonji-main:hover{
    cursor: pointer;
}

.boonji-main-img-selected{
    height: 100%;
    border-radius: 5px;
    object-fit: cover;

    outline: 5px solid #DF7DA3;
    outline-offset: -5px;
}

.boonji-main-img-selected-notOwned{
    height: 100%;
    border-radius: 5px;
    object-fit: cover;

    outline: 5px solid greenyellow;
    outline-offset: -5px;
}
.boonji-main p{
    position:absolute;
    width:96%;
    margin-left: 5px;
    bottom:0px;
    margin-bottom: 10px;
    text-align: center;
    background-color: #16011D;
    text-shadow: 0px 0px 7px #674BA2;
}

.boonji-main-Opensea{
    position:absolute;
    width:96%;
    margin-left: 5px;
    align-items: center;
    justify-items: center;
    top:10px;
    margin-top: 10px;
    text-align: center;
    background-color: #16011D;
    text-shadow: 0px 0px 7px #674BA2;
}
.boonji-main-Opensea a{
    text-decoration: none;
    color:#DF7DA3;  
}




.boonjiLine {
    padding-left: 2px;
}

.boonjiLine-background{
    background-color: #16011D;
    border-radius: 5px;
}
.boonjiCardTrait_imageBackground{
    background-color: white;
    border-radius: 3px;
}

.boonjiCardTrait_type{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    
    color: #FFFFFF;
}

.boonjiCardTrait_name{
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: normal;
    text-transform: uppercase;
    color: #FFFFFF;
    text-align: center;
}

.boonjiCard-traitSelected{
    box-shadow:inset 0px 0px 0px 3px  #A66ECD;

    outline: 3px solid #A66ECD;
    outline-offset: -3px;
}

.boonjiCard-traitNotOwned{
    box-shadow:inset 0px 0px 0px 3px  greenyellow;

    outline: 3px solid greenyellow;
    outline-offset: -3px;
    
}


.boonjiCard_TraitReady:hover{
    cursor: pointer;
}

.boonjiCard_TraitLoading:hover{
    cursor: auto
}
.boonjiCardTraitLine{
    background: #16011D;
}
.boonjiCard {
    background-color: #16011D;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 171%;
    /* identical to box height, or 41px */

    text-align: center;

    color: #FFFFFF;
    border-radius: 5px;

}
.notOwnedBoonji {
    padding: 15px;
}

.notOwnedBoonji-description {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */

    color: #DF7DA3;
}

.notOwnedBoonji-title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.notOwnedBoonji-tokenId {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.notOwnedBoonji-textField {
    color: white;
    background-color: aliceblue;
    max-width: 100px;
    max-height: 40px;
    font-size: 22px;
    padding: 15px;
  }

.notOwnedBoonji-button {
    color: black;
    background-color: black;
}

.notOwnedBoonji-list {
    padding-top: 70px;
}

.nowOwnedBoonji-item {
    padding-bottom: 10px;
}

.notOwnedBoonji-loading {
    padding-top: 20px;
    display: flex;
    justify-content: center;
}
.boonjiStudioTitle {
    padding: 25px;
}

.boonjiStudioTitle-title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    /* or 100px */
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
}

.boonjiStudioTitle-subTitle {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 100%;
    text-align: center;

    color: #FFFFFF;
}

.boonjiStudioTitle-description {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */
    text-align: center;

    color: #FFFFFF;
}

.boonjiStudio a {
    text-decoration: none;
    color:#DF7DA3;  
    font-weight: bold;
}
.ownedBoonji {
    padding: 25px;
}

.ownedBoonji-title {
    font-family:'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
    /* or 38px */
    display: flex;
    align-items: center;

    color: #FFFFFF;
    padding-bottom: 30px;
}

.ownedBoonji-amount{
    font-family:'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 23px;
    line-height: 100%;
    /* or 38px */
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 5px;

    color: #DF7DA3;
}

.ownedBoonji a {
    text-decoration: none;
    color:#DF7DA3;  
}
.fifthTraitImage{
    padding: 10px;
    background: #292929;
    border-radius: 23px;
    margin-left: 10px;
}
.fifthTraitImage img {
    margin: auto;
    width: 100%;
    height: auto;
}

.fifthTraitImage:hover{
    cursor: pointer;
}

.fifthTraitImage-selected{
    padding: 2px;
    background: #292929;
    
    border-radius: 23px;
    box-shadow:inset 0px 0px 0px 3px  #A66ECD;

    outline: -3px solid #A66ECD;
    outline-offset: -3px;
}


.fifthTraitImage-status-available {
    background: #FFFFFF;
    padding:4px;
    border-radius: 20px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-size: 11px;
    line-height: 100%;
    text-align: center;
    margin: auto;
    width: 100px;
    text-transform: uppercase;

    color: #000000;
}
.fifthTraitImage-status-reserved {
    background: grey;
    padding:4px;
    border-radius: 20px;
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-size: 11px;
    line-height: 100%;
    text-align: center;
    margin: auto;
    width: 100px;
    text-transform: uppercase;

    color: #000000;
}
.fifthTraitImage-status-reserved-img {
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */
    cursor: not-allowed;
}

.fifthTraitImage-name{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */
    display: flexbox;
    align-items: center;
    text-align: center;

    color: #FFFFFF;

    /* Purple Glow */
    text-shadow: 0px 0px 7px #674BA2;

}
.container {
    padding: 10px;
    background: black;
    height: auto;
  }
  
  .slick-slide img {
    margin: auto;
  }
  
  
  .variable-width .slick-slide p {
    background: blue;
    height: 100px;
    color: #fff;
    margin: 5px;
    line-height: 100px;
    text-align: center;
  }
.fifthTrait {
    padding: 20px;
}


.fifthTrait-title{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 120%;
    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.fifthTrait-subTitle{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */
    display: flex;
    align-items: center;
    padding-bottom: 30px;

    color: #DF7DA3;
}

.fifthTrait-type {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding-bottom: 20px;

    color: #FFFFFF;
}

.fifthTrait-signature {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    padding-bottom: 20px;

    color: #DF7DA3;
}

.fifthTrait-carousel {
    padding-left: 10px;
}
.previewMobile-boonji{
    padding-top: 10px;
    width: 30px;
    height: 30px;
}
.previewMobile-image{
    border-radius: 10px;
}
.previewMobile{
    border: 2px solid #674BA2;
    border-radius: 20px;
    width: 160px;
    height: 160px;
}
.previewMobile-trait{
    padding-top: 10px;
    width: 30px;
    height: 30px;
}
.purple-btn-rect-click{
    background-color: #593b83;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color:#fff;
    padding: 9px 15px;
    text-decoration: none;
    text-align: center;
    
    border-radius: 25px;

    min-width:100px;
    margin-left:10px;
    overflow:hidden;
}

.purple-btn-rect-click:hover {
    background-color: #784fb1;
    cursor: pointer;
}

.purple-btn-rect-click-disabled{
    background-color: grey;
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 500;
    color:#fff;
    padding: 9px 15px;
    text-decoration: none;
    text-align: center;
    
    border-radius: 25px;

    min-width:100px;
    margin-left:10px;
    overflow:hidden;
}
.purple-btn-rect-click-disabled:hover{
    background-color: gray;
    cursor:auto;
}
.inProgress {
    background: black;
    background: url(/static/media/new-twinkle.661ae23e.gif);
    border: 2px solid #674BA2;
}


.inProgress-title{
    font-family: 'DM Sans', sans-serif;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 80%;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 15px;
}
.inProgress-message{
    font-family: 'DM Sans', sans-serif;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 80%;
    text-align: center;
    padding-bottom: 15px;
}

.confirmation2 {
    display: block;
    margin-bottom: 150px;
}

.confirmation2-background{
    border: 2px solid #674BA2;
    background: url(/static/media/new-twinkle.661ae23e.gif);
}

.confirmation2-title{
    font-family: 'DM Sans', sans-serif;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
}

.confirmation2-approve{
    font-family: 'DM Sans', sans-serif;
    color: pink;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.confirmation2-mint{
    font-family: 'DM Sans', sans-serif;
    color: pink;
    font-style: normal;
    font-weight: bold;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.confirmation3{
    padding: 5px;
}

.confirmation3 a{
    text-decoration: none;
    color: violet;  
}

.confirmation3-description{
    font-family: 'DM Sans', sans-serif;
    color: pink;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 80%;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.confirmation {
    background: black;
    background: url(/static/media/new-twinkle.661ae23e.gif);
    border: 2px solid #674BA2;
}

.confirmation-title{
    font-family: 'DM Sans', sans-serif;
    color: #fff;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 80%;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 15px;
    
}

.confirmation-traits-title{
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 100%;
    /* or 26px */
    text-align: center;
    padding: 5px;
    color: #FFFFFF;
}

.confirmation-fifth-trait{
    padding-top: 10px;
    padding-bottom: 10px;
}

.confirmation-fifth-trait-text{
    padding: 5px;
    color: #fff;
    align-items: center;
    text-decoration: none;
    line-height: 10px;
    height: 100%;
    font-size: 12px;
    font-family: 'DM Sans', sans-serif;
}

.confirmation-boonjai{
    padding: 5px;
    color: #fff;
    align-items: center;
    text-decoration: none;
    line-height: 20px;
    height: 100%;
    font-size: 14px;
    font-family: 'DM Sans', sans-serif;
}

.confirmation-actions{
    background: url(/static/media/new-twinkle.661ae23e.gif);
}

.confirmation-invisible {
    display: none;
    visibility: hidden;
}
.boonjiStudio{
    background: url(/static/media/superBoonji_background.bcedc467.png), linear-gradient(180deg, #6A1A62 0%, #530355 0.01%, #16011D 100%);
}


.estudio-container{
   display: block;
   margin: 0px auto 80px auto;
   max-width: 1200px;
}
.boonji-estudio-my-boonjai{
    width:100%;
    display: flex;
    justify-content: left;
    margin: 30px 0px;
}

.boonjiStudio-preview {
    top: 0;
    position: -webkit-sticky;
    position: sticky;
}

.boonjiStudio-previewMobile {
    top: 65%;
    background-color: #16011D;
    border-radius: 20px;
    margin-left: 55%;
    /* width: 250px; */
    /* position: fixed; */
    position: -webkit-sticky;
    position: sticky;
    z-index: 9999;
}


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'DM Sans', sans-serif;
  }

body{
  background-color: #000;
  background-position: 50% 0%;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: fixed;
  font-family: 'DM Sans', sans-serif;
}

section{
  margin-top: 100px;
  margin-bottom: 100px;
  width:100%;
  display: flex;
  justify-content: center;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
  margin-bottom: 10px;
}

h2{
  font-size: 32px;
  color:#fff;
  margin-bottom: 25px;
}

h3{
  margin-bottom: 20px;
  color: #df7da3;
  font-size: 12px;
  text-align: left;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 25px;
}

p{
  margin-bottom: 20px;
  color: #fff;
  font-size: 16px;
  line-height: 160%;
  margin-top: 0px;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  letter-spacing: 1px;
  text-indent: 0px;
  white-space: normal;
}
 
 
