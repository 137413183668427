.selectedTraits {
    border-radius: 10px;
}

.selectedTraits-trait{
    border-radius: 10px;
    width: auto;
    height: 70px;
    background: #FFFFFF;
}

.selectedTraits-trait:hover{
    cursor: pointer;
}

.selectedTraits img {
    border-radius: 10px;
    width: auto;
    height: 100%;
}

.selectedTraits-notSelected{
    /* border: 2px dotted palevioletred; */
    outline-offset: -2px;
    outline: 2px solid gray;
}

.selectedTraits-selected{
    /* border: 2px solid palevioletred; */
    outline-offset: -2px;
    outline: 2px solid palevioletred;
}

.selectedTraits-selected-notOwned{
    /* border: 2px solid greenyellow; */
    outline-offset: -2px;
    outline: 2px solid greenyellow;
}

.selectedTraits-type {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 171%;
    /* identical to box height, or 26px */
    text-align: center;

    color: #FFFFFF;
}