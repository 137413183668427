.road-map-section{
    display:block;
    text-align: center;
    padding: 0 40px;
    max-width: 1170px;
    margin: auto;
}

.road-map-section h2{
    font-size: 60px;
    font-weight: bold;
    text-align: center;
}

.road-map-section h3{
    text-align: center;
    font-size: 20px;
    line-height: 100%;
    letter-spacing: 4px;
}

.road-map-p{
    margin-bottom: 20px;
    color: #fff;
    font-size: 20px;
    line-height: 130%;
    font-weight: 700;
    text-align: left;
    font-weight: 700;
}

.quote-container{
    padding: 0 21%;
    text-align: justify;
}

.quote{
    border-left: 5px solid #df7da3;
    color: #fff;
    font-size: 18px;
    line-height: 26px;
    text-align: justify;
    letter-spacing: 1px;
    text-transform: none;
    font-size: bold;
    padding: 10px 20px;
    margin-top: 40px;
    margin-bottom: 0px;
}

.author{
    text-align: right;
    margin-top: 0px;
    color: #fff;
    font-size: 24px;
    font-weight: 700;
}

@media screen and (max-width: 992px) {
    .road-map-p{
        font-size: 18px !important;
    }

    .quote-container{
        padding: 0 6%;
    }

    .quote{
        font-size: 16px;
        line-height: 120%;
    }
}

@media screen and (max-width: 768px) {

    .road-map-section{
        padding: 0 30px;
    }
}

@media screen and (max-width: 425px) {

    .road-map-section h2{
        font-size: 40px;
        padding: 0px 50px;
        line-height: 45px;
    }

    .road-map-section h3{
        line-height: 25px;
        padding: 0px;
        font-size: 14px;
    }

    .road-map-section{
        padding: 0 15px;
    }

    .quote-container{
        padding: 0 5px;
        text-align: justify;
    }

    .road-map-h2{
        font-size: 20px !important;
        letter-spacing: 6px !important;
        font-weight: bold;
        text-align: center;
    }

}