.notOwnedBoonji {
    padding: 15px;
}

.notOwnedBoonji-description {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */

    color: #DF7DA3;
}

.notOwnedBoonji-title {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.notOwnedBoonji-tokenId {
    font-family: 'DM Sans', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 120%;
    display: flex;
    align-items: center;

    color: #FFFFFF;
}

.notOwnedBoonji-textField {
    color: white;
    background-color: aliceblue;
    max-width: 100px;
    max-height: 40px;
    font-size: 22px;
    padding: 15px;
  }

.notOwnedBoonji-button {
    color: black;
    background-color: black;
}

.notOwnedBoonji-list {
    padding-top: 70px;
}

.nowOwnedBoonji-item {
    padding-bottom: 10px;
}

.notOwnedBoonji-loading {
    padding-top: 20px;
    display: flex;
    justify-content: center;
}