.crew-ui-container{
    display:flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
}

.crew-ui-img img{
    width:100%;
    max-width: 190px;
    border-style: solid;
    border-width: 5px;
    border-color: #593b83;
    border-radius: 300px;
    border-radius:300px;
}

.crew-ui-name{
    margin-bottom: 5px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-top: 10px;
}

.crew-ui-position{
    margin-bottom: 5px;
    color: #fff;
    font-size: 14px;
    line-height: 16px;
    margin-top: 0;
}

.crew-ui-social{
    color: #fff;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.crew-ui-social a{
    text-decoration: none;
    margin-left: 5px;
}

.crew-ui-social span{
    cursor: pointer;
}

.crew-ui-social img{
    margin-top: 4px;
}

.crew-ui-popup{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    padding-top: 10px;
    padding-right: 8px;
    padding-left: 8px;
    align-items: center;
    background-color: rgba(41, 23, 67, 0.95);
    display:flex;
    flex-direction: column;
    border-radius: 20px;
    height: 100%;
}

.crew-ui-popup-x-div{
    width:100%;
    text-align: right;
}

.crew-ui-popup-x-div img{
    width:15px;
    opacity: 0.4;
    cursor: pointer;
}

.crew-ui-popup-x-div img:hover{
    opacity: 1;
}

.crew-ui-popup h4{
    margin-top: 10px;
    margin-bottom: 0px;
    color: #fff;
    font-size: 14px;
    line-height: 130%;
    font-weight: 700;
}

.crew-ui-popup-position{
    color: #fff;
    font-size: 12px;
    line-height: 130%;
}

.crew-ui-popup-divider{
    width: 100px;
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #784fb1;
}

.crew-ui-popup-p{
    color: #fff;
    font-size: 12px !important;
    margin-bottom: 10px;
    line-height: 120%;
    padding: 0px;
}
